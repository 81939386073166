const ColumnsModel = {
  headerTitle: "Sales at Model Level",
  columns: [
    {
      "label": "Model",
      "column": "model",
      "key": "model",
      "dataStyle": "text",
      "width": '160px'
    },
    {
      "label": "Units Sold",
      "column": "total_qty",
      "key": "total_qty",
      "dataStyle": "integer",
    },
    {
      "label": "Avg Unit Price",
      "column": "avg_unit_price",
      "key": "avg_unit_price",
      "dataStyle": "money",
    },
    {
      "label": "Order Total",
      "column": "total_price",
      "key": "total_price",
      "dataStyle": "money",
    },
    {
      "label": "Avg Landed Cost",
      "column": "landed_cost",
      "key": "landed_cost",
      "dataStyle": "money",
      "width": '180px'
    },
    {
      "label": "Total Landed Cost",
      "column": "total_landed_cost",
      "key": "total_landed_cost",
      "dataStyle": "money",
      "width": '180px'
    },
    {
      "label": "Margin%",
      "column": "margin_percent",
      "key": "margin_percent",
      "dataStyle": "percent",
    },
    {
      "label": "Qty",
      "column": "catalog_qty",
      "key": "catalog_qty",
      "dataStyle": "text",
      "width": '80px'
    },
    {
      "label": "EDI/PC Qty",
      "column": "edi_qty",
      "key": "edi_qty",
      "dataStyle": "text",
      "width": '120px'
    }, 
    {
      "label": "APO",
      "column": "apo",
      "key": "apo",
      "dataStyle": "html",
      "width": '120px'
    }, 
    {
      "label": "Avg Sales",
      "column": "avg_month_sales",
      "key": "avg_month_sales",
      "dataStyle": "text",
      "width": '120px'
    },
    {
      "label": "Freight Type",
      "column": "freight_class",
      "key": "freight_class",
      "dataStyle": "text",
      "width": '120px'
    }
  ]
}

const ColumnsOrder = {
  headerTitle: "Sales at Order Level",
  columns: [
    {
      "label": "SO_Number",
      "column": "so_id",
      "key": "so_id",
      "dataStyle": "text",
      "width": "150px"
    },
    {
      "label": "Units Sold",
      "column": "total_qty",
      "key": "total_qty",
      "dataStyle": "integer",
      "width": "100px"
    },
    {
      "label": "Order Total",
      "column": "total_price",
      "key": "total_price",
      "dataStyle": "money",
      "width": "150px"
    },
    {
      "label": "Total Landed Cost",
      "column": "total_landed_cost",
      "key": "total_landed_cost",
      "dataStyle": "money",
      "width": "150px"
    },
    {
      "label": "Margin%",
      "column": "margin_percent",
      "key": "margin_percent",
      "dataStyle": "percent",
      "width": "95px"
    },
    {
      "label": "Invoice Date",
      "column": "invoice_date",
      "key": "invoice_date",
      "dataStyle": "text",
      "width": "115px"
    },
    {
      "label": "Ship to",
      "column": "ship_to",
      "key": "ship_to",
      "dataStyle": "text",
      "width": "240px"
    }
  ]
}

const ColumnsSummary = {
  headerTitle: "Order Type Summary",
  columns: [
    {
      "label": "Order Type",
      "column": "billto_cid",
      "key": "billto_cid",
      "dataStyle": "text",
      "fixed":true,
      "width": "130x"
    },
    {
      "label": "Orders",
      "column": "order_count",
      "key": "order_count",
      "dataStyle": "integer",
      "width": "80px"
    },
    {
      "label": "Total Revenue",
      "column": "total_price",
      "key": "total_price",
      "dataStyle": "money",
      "width": "160px"
    },
    {
      "label": "Total Landed Cost",
      "column": "total_landed_cost",
      "key": "total_landed_cost",
      "dataStyle": "money",
      "width": "160px"
    },
    {
      "label": "Total Rebate",
      "column": "total_rebates",
      "key": "total_rebates",
      "dataStyle": "money",
      "width": "140px"
    },
    {
      "label": "Margin%",
      "column": "margin_percent",
      "key": "margin_percent",
      "dataStyle": "percent",
      "width": "95px"
    },
    {
      "label": "Total%",
      "column": "revenue_percent",
      "key": "revenue_percent",
      "dataStyle": "percent",
      "width": "95px"
    }
  ]
}

const ColumnsAllowanceSettingAds = {
  columns: [
    {
      "label": "State",
      "column": "state",
      "dataStyle": "text",
    },
    {
      "label": "ASIN",
      "column": "asin",
      "dataStyle": "text",
    },
    {
      "label": "ERP Model",
      "column": "erp_model",
      "dataStyle": "text",
    },
    {
      "label": "Promo",
      "column": "promo",
      "dataStyle": "text",
    },
    {
      "label": "Products",
      "column": "products",
      "dataStyle": "text",
    },
  ]
}

export { ColumnsModel, ColumnsOrder, ColumnsSummary, ColumnsAllowanceSettingAds }